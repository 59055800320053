@media screen {
  .roadshow-print {
    display: none;
  }
}

@media print {
  .socgen-color {
    color: #e60028;
  }
  .break-inside-avoid {
    break-inside: avoid;
  }
  .roadshow-container {
    display: none !important;
  }
  .roadshow-print {
    display: block;

    .slot-location {
      text-decoration: none;
    }
  }
  .roadshow-invitations {
    break-before: page;
  }
}