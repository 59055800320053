.full-size {
  flex-direction: column;
  display: flex;
}

.full-size>iframe {
  width: 100%;
  min-height: calc(100vh - 140px);
}

