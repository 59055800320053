@import "header";
@import "print";
@import "~@ic-anywhere/ic-components/dist/ic-components.css";

:global {
  html,
  body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;

    .main {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      min-height: 82vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      > div {
        flex-shrink: 0;
        flex-basis: auto;
      }
    }

    .container {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-content: stretch;

      > div {
        flex-shrink: 0;
        flex-basis: auto;
      }
    }

    .container-header {
      align-content: stretch;
      overflow-y: hidden;

      > div {
        width: 100%;
        flex-shrink: 0;
        flex-basis: auto;
      }

      > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    sgwt-mini-footer {
      align-self: flex-end;
      align-self: stretch;
    }
  }

  header,
  footer {
    flex-shrink: 0;
  }

  .pdf-root-container {
    margin-left: 1.5rem !important;
  }

  .nav-header {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }

  .nav-header::-webkit-scrollbar {
    height: 1px;
  }

  .nav-header::-webkit-scrollbar-thumb {
    background: #6e777a;
  }

  .icon {
    .icon-sg-event {
      width: 24px;

      &.icon-sm {
        width: 18px;
      }

      &.icon-lg {
        width: 32px;
      }

      &.icon-xl {
        width: 48px;
      }
    }
  }

  // Hack fixing the visibility of the Avatar text when is display none in an accordion for example.
  .sb-avatar {
    &__text {
      > div {
        font-size: medium !important;
      }
    }
  }

  .card-header [data-toggle="collapse"]:not(.collapsed) .icon {
    transform: rotate(90deg);
  }

  // CUSTOM STYLE FOR ERROR PAGES, 404, 500, 503
  @media (min-width: 992px) {
    .section-1 {
      background: linear-gradient(90deg, #fff 50%, rgba(250, 250, 250, 0) 0);
    }

    .section-2,
    .section-3 {
      background-size: 6px 6px;
      background-image: linear-gradient(
        -45deg,
        #fafafa 46%,
        rgba(48, 51, 51, 0.7) 49%,
        rgba(48, 51, 51, 0.7) 51%,
        #fafafa 55%
      );
    }

    .section-2 > .container-fluid {
      background: linear-gradient(90deg, #ffffff 50%, rgba(250, 250, 250, 0) 0);
    }

    .section-3 > .container-fluid {
      background: linear-gradient(90deg, #fafafa 50%, rgba(250, 250, 250, 0) 0);
    }
  }

  .text-outline-socgen {
    text-shadow: -1px 0 #e60028, 0 1px #e60028, 1px 0 #e60028, 0 -1px #e60028;
  }
}

// IE10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card {
    flex: 1 0 100%;
  }

  .timeline-item {
    flex: none;
  }
}

.input-group-prepend.input-group-merged {
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: slategrey;
  padding-left: 15px;
  padding-top: 7px;
}

#search-by-event-name {
  padding-left: 40px;
}

.nav-link.active:not(.disabled) {
  border-bottom: solid;
  font-weight: 600;
}

.spinner-grow {
  width: 0%;
}
