.cursor-pointer {
  cursor: pointer;
  user-select: none;
}
.ic-map {
  > a {
    display: inline-block;
    border: 1px solid #ccc;
    > img {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 600px;
    }
  }
}
